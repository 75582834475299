.aboutMeParent {
    margin: 80px 0px;
    padding: 16px;
}

.constTitle {
    font-size: x-large;
    display: block;
    text-align: left;
    margin: 8px;
}

.constDesc {
    font-size: small;
    display: block;
    text-align: center;
    margin: 8px;
}

.constSubTitle {
    font-size: medium;
    display: block;
    text-align: left;
    margin: 4px 8px 24px 8px;
    text-decoration-line: underline;
    text-underline-offset: 6px;
}

.aboutLinks {
    margin: 16px -4px;
    align-items: start;
    display: flex;
}