.affiliate_logo {
    margin: auto 8px;
}

.logos {
    margin: 8px;
    vertical-align: middle;
    display: inline-block;
    padding: 8px;
    border-radius: 12px;
}

.source {
    margin: 8px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    border-radius: 12px;
    padding: 8px 12px;
    font-weight: bold;
    text-decoration: none;
}

.bioGif {
    border-radius: 50%;
    object-fit: cover;
}

.bioContent {
    line-height: 1.8em;
    margin-top: 16px;
    padding: 8px;
}

.verticalCenterBio {
    margin: auto 0px;
    font-size: small;
}

.sourceIcon {
    font-size: x-small;
    margin-top: auto;
    font-weight: bold;
}