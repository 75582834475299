.App {
  text-align: center;
}

.dashboard-items {
  margin: 80px 0px
}

.final-footer {
  margin-top: 16px;
  display: block;
  font-size: x-small;
}

html {
  scroll-behavior: smooth;
}