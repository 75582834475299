.frequency {
    font-size:x-small;
    margin-top: 8px;
    margin-bottom: 4px;
}

.frequency > span {
    font-size: xx-small;
    margin: 2px;
}

.title {
    font-weight: bold;
}

.description {
    font-size:small;
}

.sourceLink {
    text-decoration: none;
    padding: 8px 12px;
    margin: 12px 0px 4px 0px;
    font-size: smaller;
    border-radius: 12px;
    display: block;
    width: 100px;
    text-align: center;
    font-weight: bold;
}

.footer {
    margin-top: 16px;
}

.noteBody {
    width: 47%;
    height: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 1.5%;
    padding: 16px;
    border-radius: 12px;
    text-align: left;
}