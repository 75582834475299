.year {
    font-size:x-small;
    margin-top: 8px;
    margin-bottom: 4px;
}

.title {
    font-weight: bold;
}

.description {
    font-size:small;
}

.sourceLink {
    text-decoration: none;
    padding: 8px 12px;
    margin: 12px 4px 4px 4px;
    font-size: smaller;
    border-radius: 12px;
    display: inline-block;
    min-width: 120px;
    text-align: center;
    font-weight: bold;
}

.sourceLink > span {
    font-size: x-small;
    margin-top: auto;
    font-weight: bold;
}

.footer {
    margin-top: 16px;
}

.projectBody {
    width: 47%;
    min-height: 200px;
    height: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 1.5%;
    padding: 16px;
    border-radius: 12px;
    text-align: left;
}

.langLogo {
    margin: 4px 8px 4px 4px;
}

.langLogoCollection {
    display: inline-block;
    /* padding: 4px; */
    /* margin: 8px 4px; */
    /* border-radius: 12px; */
    height: 36px;
}

.projectTitle {
    display: inline-block;
}

.projectHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}