.navbar-toggler {
    border: 0
}

.navbar-collapse {
    justify-content: end;
}

.theme-toggle {
    justify-content: center;
}

.active {
    text-decoration-line: underline;
    text-underline-offset: 4px;
}