.allNotesParent {
    margin: 80px 0px;
}

.notesTitle {
    font-size: medium;
    display: block;
    text-align: center;
}

.notesDesc {
    font-size: small;
    display: block;
    text-align: center;
    margin: 8px;
}